<template>
  <div class="renew">
    <footer class="footer">
      <div class="max-w-1200-wrap footer-heading">
        <ul class="max-w-1200">
          <li>
            <router-link to="/policy/rules">이용약관</router-link>
          </li>
          <li>
            <router-link to="/policy/privacy">개인정보처리방침</router-link>
          </li>
          <li>
            <router-link to="/policy/electTransPolicy"
              >전자금융거래 이용약관</router-link>
          </li>
<!--          <li>
            <span  @click="goToGuideDoc()" class="guide"
            >서비스이용안내</span>
          </li>-->
        </ul>
      </div>
      <div class="max-w-1200-wrap footer-content">
        <div class="max-w-1200">
          <img src="@/assets/logo-new-white.svg" alt="" loading="lazy" />
          <div class="footer-detail">
            <span class="footer-detail-content">상호명 : 씨에이치 플래닛(ch planet)</span>
            <span class="footer-detail-content">대표자 : 이승엽 </span >
            <span class="footer-detail-content">사업자등록번호 : 506-79-00294</span>
          </div>
          <div class="footer-detail">
            <span class="footer-detail-content">대표전화번호 : 02-6925-3270</span>
            <span class="footer-detail-content">이메일 : sales@weselpay.com</span>
            <span class="footer-detail-content">주소 : 서울특별시 송파구 송파대로28길 32 올림피아 715호</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>


export default {
  name: "Footer",
  methods:{
    goToGuideDoc(){
      window.open(process.env.VUE_APP_WEBMAIN_HOST + '/help/guide', '이용안내', "width=1000, height=800");
      //return process.env.VUE_APP_WEBMAIN_HOST + '/help/guide'
    }
  }
}
</script>

<style scoped>

.guide:hover {
  cursor: pointer;
}

.footer {
  background-color: #1D1D1D;
  color: #E7E7E7;
}

.footer-heading {
  border-bottom: 1px solid #FFFFFF;
  padding: 0 282px;
  padding: 0 14.6875vw;
}

.footer-heading ul {
  display: flex;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.footer-heading ul li {
  display: inline-flex;
  align-items: center;
  height: 71px;
}

.footer-heading ul li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  color: rgb(231, 231, 231);
  transition: 0.3s;
  border-radius: 4px;
  line-height: 2.43;
  letter-spacing: -0.37px;
}

.footer-heading ul li a:hover {
  background-color: rgba(66, 66, 66, 0.08);
}

.footer-content {
  padding-right: 0;
  padding-left: 306px;
  padding-left: 15.938vw;
}

.footer-content > div {
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  padding-bottom: 61px;
  margin: 0;
}

.footer-content img {
  width: 221px;
  height: auto;
  margin-bottom: 24px;
}

.footer-detail {
  display: flex;
  font-size: 18px;
  line-height: 2.437;
  letter-spacing: -0.66px;
  text-align: center;
}

.footer-detail-content {
  padding-right: 44px;
}

@media screen and (max-width: 1312px) {

  .footer-heading ul {
    font-size: 1.143vw;
  }

  .footer-heading ul li {
    height: 5.412vw;
  }

  .footer-heading ul li a {
    padding: 0.762vw 1.829vw;
    border-radius: 0.305vw;
    letter-spacing: -0.0282vw;
  }

  .footer-content > div {
    padding-top: 3.963vw;
    padding-bottom: 4.649vw;
  }

  .footer-content img {
    width: 16.845vw;
    margin-bottom: 1.829vw;
  }
  .footer-detail {
    font-size: 1.372vw;
    letter-spacing: -0.0343vw;
  }

  .footer-detail-content {
    padding-right: 3.354vw;
  }
}

@media screen and (max-width: 600px) {
  .footer-heading {
    border-bottom: 1px solid #FFFFFF;
    padding: 0 0 0 31px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .footer-heading ul {
    font-size: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .footer-heading ul li {
    height: 25px;
  }

  .footer-heading ul li a {
    padding: 0 24px 0 0;
  }

  .footer-content {
    padding-left: 30px;
  }

  .footer-content > div {
    padding-top: 32px;
    padding-bottom: 41px;
    margin: 0;
  }

  .footer-content img {
    width: 121px;
    height: 33px;
    margin-bottom: 23px;
  }

  .footer-detail {
    font-size: 12px;
    flex-direction: column;
    text-align: start;
  }

  .footer-detail-content {
    padding-right: 0;
  }
}
</style>