<template>
  <v-sheet>
    <v-sheet id="IntroBanner" width="100%" class="mx-auto">
      <div class="banner-img"></div>
      <div class="banner-title" v-if="$route.path.includes('/introduce/product')">
        <span class="title-text">상품관리</span>
        <span class="subtitle-text">
          필수 입력만으로 1분 내 상품 등록과 결제를 하고<br />
          더욱 간편하게 수기 결제 주문서를 만들어보세요!
        </span>
      </div>
      <div class="banner-title" v-if="$route.path.includes('/introduce/order')">
        <span class="title-text">주문관리</span>
        <span class="subtitle-text">
          PC/모바일 구분없이 어디서든 결제/주문확인이 가능합니다.<br />
          주문시작부터 배송완료까지 편리하고<br v-if="$vuetify.breakpoint.xsOnly"> 빠르게 주문상태를 관리할 수 있습니다.
        </span>
      </div>
      <div class="banner-title" v-if="$route.path.includes('/introduce/member')">
        <span class="title-text">회원/혜택관리</span>
        <span class="subtitle-text">
          회원 관리와 혜택 관리로 <br/>
          내 쇼핑몰의 회원을 단골 고객으로 만들 수 있습니다.
        </span>
      </div>
      <div class="banner-title" v-if="$route.path.includes('/introduce/message')">
        <span class="title-text">메시지 관리</span>
        <span class="subtitle-text">
          주문상태와 고객 알림 메시지를<br />
          SMS/ 알림톡으로 발송할 수 있습니다.
        </span>
      </div>
      <div class="banner-title" v-if="$route.path.includes('/introduce/shop')">
        <span class="title-text">상점관리</span>
        <span class="subtitle-text">
          위셀페이는 구축과정 없이 가입만 하면 즉시 생성되는 쇼핑몰 제공합니다.<br />
          PC/모바일에서 접속할 수 있는 운영이 편리한 쇼핑몰을 무료로 제공되고 운영할 수 있습니다.
        </span>
      </div>
      <v-tabs color="rgba(0, 74, 131, 1)" hide-slider next-icon="mdi-arrow-right" prev-icon="mdi-arrow-left" show-arrows v-model="tabIndex">
        <v-tab color="#EFEBE9" to="/main/introduce/product">
          <label>상품/수기주문서</label>
        </v-tab>
        <v-tab color="#EFEBE9" to="/main/introduce/order">
          <label >주문관리</label>
        </v-tab>
        <v-tab color="#EFEBE9" to="/main/introduce/member">
          <label>회원/혜택관리</label>
        </v-tab>
        <v-tab color="#EFEBE9" to="/main/introduce/message">
          <label>메시지</label>
        </v-tab>
        <v-tab color="#EFEBE9" to="/main/introduce/shop">
          <label>상점관리</label>
        </v-tab>
      </v-tabs>
    </v-sheet>
  </v-sheet>
</template>
<script>
export default {
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>
<style>
#IntroBanner {
  height: 400px;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-image: url("../../../../assets/banner/introduce_banner.png");
  background-size: cover;
  background-position-y: bottom;
}
.banner-img {
  position: absolute;
  background-color: #030E28;
  opacity: 0.4;
  mix-blend-mode: multiply;
  width: 100%;
  height: 400px;
}

#IntroBanner .banner-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #fff;
  padding-left: calc(50% - 960px + 356px);
}
.banner-title > span.title-text {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.15;
  margin-bottom: 16px;
}

.banner-title > span.subtitle-text {
  font-size: 22px;
  line-height: 1.5;
}

#IntroBanner .v-tabs {
  position: absolute;
  color: rgba(66, 66, 66, 1);
  bottom: -80px;
}

#IntroBanner .v-tabs,
#IntroBanner .v-tabs > div,
#IntroBanner .v-tabs > div > div,
#IntroBanner .v-tabs > div > div > div{
  height: 80px;
}

#IntroBanner .v-tabs .v-slide-group__content {
  justify-content: center;
}
#IntroBanner .v-tabs .v-tab {
  font-size: 20px;
  line-height: 1.15;
  margin: 0 31.5px;
  padding: 0 30px;
  letter-spacing: inherit;

}
#IntroBanner .v-tabs .v-tab--active {
  font-weight: 700;
}
#IntroBanner .v-tabs .v-tab label {
  cursor: pointer;
}

@media screen and (max-width:1900px) {
  #IntroBanner {
  height: 20.833vw;
  margin: 4.167vw 0;
  }
  .banner-img {
    height: 20.833vw;
  }

  #IntroBanner .banner-title {
    padding-left: 18.542vw;
  }
  .banner-title > span.title-text {
    font-size: 2.500vw;
    margin-bottom: 0.833vw;
  }

  .banner-title > span.subtitle-text {
    font-size: 1.146vw;
  }

  #IntroBanner .v-tabs {
    bottom: -4.167vw;
  }

  #IntroBanner .v-tabs,
  #IntroBanner .v-tabs > div,
  #IntroBanner .v-tabs > div > div,
  #IntroBanner .v-tabs > div > div > div{
    height: 4.167vw;
  }

  #IntroBanner .v-tabs .v-tab {
    font-size: 1.042vw;
    margin: 0 3.203vw;
  }

}

@media screen and (max-width:720px) {
  #IntroBanner {
    margin-top: 63px;
  }
}

@media screen and (max-width:600px) {
  #IntroBanner {
    margin: 63px 0 60px 0;
    background-position: -570px -160px;
    background-size: 1631px;
    height: 250px;
  }

  .banner-img {
  height: 250px;
  }

  #IntroBanner .banner-title {
    padding-left: 15px;
  }

  .banner-title > span.title-text {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24.24px;
  }

  .banner-title > span.subtitle-text {
    font-size: 14px;
    width: 292px;
  }

  #IntroBanner .v-tabs {
    position: absolute;
    color: rgba(66, 66, 66, 1);
    bottom: -60px;
    height: 60px;
  }

  #IntroBanner .v-tabs,
  #IntroBanner .v-tabs > div,
  #IntroBanner .v-tabs > div > div,
  #IntroBanner .v-tabs > div > div > div{
    height: 60px;
  }
  #IntroBanner .v-tabs .v-slide-group__next,
  #IntroBanner .v-tabs .v-slide-group__prev {
    min-width: 40px;
  }

  #IntroBanner .v-tabs .v-slide-group__content {
    margin-left:0px;
  }
  #IntroBanner .v-tabs .v-icon {
    color:rgba(0, 0, 0, 1);
  }
  #IntroBanner .v-tabs .v-icon--disabled {
    color:rgba(0, 0, 0, 0.2)!important;
  }

  #IntroBanner .v-tabs .v-tab {
    font-size: 14px;
    margin: 0 9px;
    min-width: auto;
  }
}
</style>