<template>
    <v-sheet>
        <v-sheet id="PolicyBanner" width="100%" class="mx-auto">
            <p class="text-center text-h5 text-md-h4 font-weight-bold white--text">
                {{ title }}
            </p>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    computed: {
        title() {
            if (this.$route.path == '/rules') return '이용약관'
            if (this.$route.path == '/privacy') return '개인정보처리방침'
            if (this.$route.path == '/emaildeny') return '이메일무단수집거부'
        }
    }

}
</script>
<style>
#PolicyBanner {
    background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)),url("../../../../assets/banner/join.jpg");
    background-position: center;
    background-size: cover;
    height: 400px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

@media screen and (max-width:1312px) {
    #PolicyBanner {
        padding-top: 6.098vw;
        height: 300px;
    }
}

@media screen and (max-width:650px) {
    #PolicyBanner {
        background-size: auto 544px;
        margin-top: 63px;
        padding-top: 0;
        height: 164px;
    }
}
</style>