<template>
  <section ref="container" class="event-main max-w-1200-wrap">
    <div class="event-main__inner max-w-1200">
      <div class="event-main__left">
        <h2>
          위셀페이에 가입하고<br />
          <strong>무료로 반응형 쇼핑몰</strong>
          을<br />
          만들어보세요.
        </h2>
        <p>#무료이용 #인스타공구 #간편결제 #간편결제주문서</p>
        <p class="mt-4">가입즉시 구축과정 없이 바로 쇼핑몰 생성!</p>
      </div>
      <img
        class="event-main__img"
        src="../../../assets/event/event-main.png"
        alt=""
      />
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
export default {
  data() {
    return {
      model: 0,
    };
  },
  mounted() {
    gsap.to(this.$refs.container.querySelector("h2"), {
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 0.3,
    });
  },
};
</script>
<style scoped>
.event-main {
  height: 780px;
  background-image: url("../../../assets/event/event-main-bg.jpg");
  background-size: cover;
  background-position: center;
  color: #fff;
}

.event-main__inner {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.event-main h2 {
  font-weight: 300;
  font-size: 48px;
  opacity: 0;
  transform: translateY(50px);
}

.event-main p {
  font-weight: 300;
  font-size: 24px;
  margin-top: 16px;
}

.event-main img {
  width: 626.52px;
  height: auto;
}

@media screen and (max-width: 1312px) {
  .event-main h2 {
    font-size: 3.6585vw;
  }

  .event-main p {
    font-weight: 300;
    font-size: 1.8293vw;
    margin-top: 1.2195vw;
  }

  .event-main img {
    width: 47.753vw;
    height: auto;
  }
}

@media screen and (max-width: 650px) {
  .event-main {
    height: calc(152vw + 63px);
    padding-top: 63px;
    background-position: 72%;
  }

  .event-main__inner {
    flex-direction: column;
    justify-content: center;
  }

  .event-main h2 {
    font-size: 6.4vw;
  }

  .event-main p {
    font-weight: 300;
    font-size: 3.7333vw;
    margin-top: 3.2vw;
  }

  .event-main img {
    width: 83.4667vw;
    height: auto;
    margin-top: 26.6667vw;
  }
}
</style>