import { render, staticRenderFns } from "./HomeSection6.vue?vue&type=template&id=35e6579c&scoped=true&"
var script = {}
import style0 from "./HomeSection6.vue?vue&type=style&index=0&id=35e6579c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e6579c",
  null
  
)

export default component.exports