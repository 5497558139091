<template>
  <section class="section" ref="container">
    <v-carousel
      v-model="model"
      :show-arrows="false"
      hide-delimiters
      height="auto"
      @change="onSlide"
      :cycle="cycle"
      :interval="8000"
    >
      <v-carousel-item>
        <div class="max-w-1200-wrap">
          <div class="max-w-1200">
            <div class="slide slide-1">
              <h3>
                SNS 판매는<br />
                <strong>위셀페이</strong>와 함께
              </h3>
              <ul>
                <li>
                  <span>1</span>
                  <div>
                    <h4>최고의<br /><strong> 비용절감</strong></h4>
                    <p>SMS <strong>15,000</strong>건 무료</p>
                    <p>PG/결제 수수료 최저 <strong>1.6%</strong></p>
                    <p>PG가입비, 보증보험료 <strong>면제</strong></p>
                  </div>
                </li>
                <li>
                  <span>2</span>
                  <div>
                    <h4><strong>간편한 주문서</strong><br /> 생성과 결제</h4>
                    <p><strong>손쉬운</strong> 상품 관리</p>
                    <p><strong>간편한</strong> 주문서 생성</p>
                    <p><strong>빠르고 쉽게</strong> 주문서 공유</p>
                  </div>
                </li>
                <li>
                  <span>3</span>
                  <div>
                    <h4>
                      <strong>편리하고 쉬운</strong><br />
                      판매관리
                    </h4>
                    <p><strong>운영 편리한</strong> 쇼핑몰 제공</p>
                    <p><strong>어디서든</strong> 결제/주문 확인</p>
                    <p><strong>편리한</strong> 주문 관리</p>
                  </div>
                </li>
              </ul>
              <div class="phone-grid hidden-lg">
                <img
                  src="../../../assets/home-new/app_1.png"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="../../../assets/home-new/app_2.png"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="../../../assets/home-new/app_3.png"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="../../../assets/home-new/app_4.png"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div class="phone-grid hidden-sm">
                <img
                  src="../../../assets/home-new/app_m_1.png"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="../../../assets/home-new/app_m_2.png"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="../../../assets/home-new/app_m_3.png"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="../../../assets/home-new/app_m_4.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </v-carousel-item>
      <v-carousel-item>
        <div class="max-w-1200-wrap">
          <div class="max-w-1200">
            <div class="slide slide-2">
              <h3>
                더 빠르고<br class="hidden-lg" />
                간편하고 쉽게!
              </h3>
              <p>
                필수정보만 입력하면 신속하고 간편하게 상품등록을 할 수
                있습니다.<br />
                또한 다양한 주문 상태를 관리할 수 있는 메뉴를 통해 쉽고 빠르게
                주문서를 관리할 수 있습니다.
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/home-new/speed.png"
                    alt=""
                    loading="lazy"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/home-new/simple.png"
                    alt=""
                    loading="lazy"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/home-new/easy.png"
                    alt=""
                    loading="lazy"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="max-w-1200-wrap">
      <div class="max-w-1200">
        <div class="slide-controls">
          <button type="button" @click="back">
            <img
              src="../../../assets/home-new/chevron-right-green.svg"
              alt=""
              loading="lazy"
            />
          </button>
          <button type="button" @click="next">
            <img
              src="../../../assets/home-new/chevron-right-green.svg"
              alt=""
              loading="lazy"
            />
          </button>
          <div class="progress-wrap">
            <div class="progress">
              <div :style="`transform: scaleX(${(model + 1) * 50}%)`"></div>
            </div>
            <span>{{ model + 1 }}/2</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
export default {
  data() {
    return {
      model: 0,
      cycle: false,
    };
  },
  mounted() {
    gsap.to(this.$refs.container.querySelectorAll(".slide-1 ul li"), {
      scrollTrigger: {
        trigger: this.$refs.container.querySelector(".slide-1 ul"),
        toggleActions: "play none none reset",
        onEnter: () => {
          this.cycle = true;
        },
      },
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 0.3,
      stagger: 0.3,
    });
  },
  methods: {
    onSlide() {
      gsap.set(
        this.$refs.container.querySelectorAll(
          `.slide-${(this.model % 2) + 1} ul li`
        ),
        {
          opacity: 0,
          y: 50,
        }
      );
      setTimeout(() => {
        gsap.fromTo(
          this.$refs.container.querySelectorAll(
            `.slide-${(this.model % 2) + 1} ul li`
          ),
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 0.5, stagger: 0.3 }
        );
      }, 300);
    },
    back() {
      if (!this.model) {
        return;
      }
      this.model--;

      if (window.innerWidth < 651) {
        return;
      }

      this.onSlide();
    },
    next() {
      this.model++;

      if (window.innerWidth < 651) {
        return;
      }

      this.onSlide();
    },
  },
};
</script>

<style scoped lang="scss">
.renew {
  .section {
    position: relative;
  }

  .v-carousel {
    .max-w-1200-wrap {
      height: 780px;
      // min-height: 780px;
      overflow: hidden;
    }

    .max-w-1200 {
      position: relative;
      height: 100%;
    }
  }

  .slide {
    display: flex;
    flex-direction: column;
    padding-top: 90px;
    padding-bottom: 183px;
    height: 100%;
  }

  .slide ul {
    margin-top: auto;
    margin-bottom: auto;
  }

  .slide ul li {
    display: inline-flex;
    opacity: 0;
    transform: translateY(50px);
  }

  .slide-1 ul li {
    flex-direction: column;
  }

  .slide-1 h3 {
    position: relative;
    z-index: 1;
    color: #424242;
    font-size: 48px;
    line-height: 55px;
    font-weight: 300;
  }

  .phone-grid {
    position: absolute;
    // height: 110vh;
    height: 858px;
    // min-height: 858px;
    top: -6%;
    right: -136px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .phone-grid.hidden-sm {
    display: none;
  }

  @media screen and (max-width: 1440px) {
    .phone-grid {
      right: -80px;
    }
  }

  .phone-grid img {
    position: relative;
    width: 226px;
    height: auto;
    filter: drop-shadow(-10px 40px 20px rgba(0, 0, 0, 0.25));
  }

  .phone-grid img:nth-child(2n + 1) {
    // top: 12vh;
    top: calc(0.12 * 780px);
  }

  .slide-1 ul {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 20px;
  }

  .slide-1 ul li > span {
    color: #00aba9;
    font-size: 80px;
    line-height: 92px;
    font-weight: 700;
  }

  .slide-1 ul li > div {
    position: relative;
    width: 284px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #f4f4f4;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 40px 24px;
    margin-top: -20px;
  }

  .slide-1 ul li > div h4 {
    color: #424242;
    font-size: 36px;
    line-height: 41px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .slide-1 ul li > div h4 strong {
    color: #00aba9;
  }

  .slide-1 ul li > div p {
    font-size: 16px;
    color: #424242;
  }

  .slide-1,
  .slide-2 {
    display: flex;
    flex-direction: column;
  }

  .slide-controls {
    position: absolute;
    bottom: 57px;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .slide-controls button {
    display: inline-flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #00aba9;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  .slide-controls button img {
    width: 40px;
    height: 40px;
  }

  .slide-controls button:nth-child(2) {
    margin-left: 20px;
  }

  .slide-controls button:nth-child(2) img {
    transform: rotateZ(180deg) translateX(-10%);
  }

  .progress-wrap {
    display: flex;
    align-items: center;
    margin-left: 32px;
  }

  .progress {
    position: relative;
    height: 3px;
    width: 340px;
    background-color: #d2d2d2;
    margin-right: 20px;
  }

  .progress > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: scaleX(50%);
    transform-origin: left;
    background-color: #00aba9;
    height: 3px;
    transition: 0.3s;
  }

  .progress-wrap span {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #424242;
  }

  .slide-2 {
    padding-top: 90px;
    height: 100%;
  }

  .slide-2 h3 {
    color: #424242;
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 16px;
  }

  .slide-2 p {
    font-size: 20px;
    color: #424242;
  }

  .slide-2 ul {
    display: flex;
    gap: 24px;
  }

  .slide-2 ul img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 1312px) {
    .v-carousel {
      .max-w-1200-wrap {
        height: 59.451vw;
      }
    }

    .slide {
      padding-top: 6.86vw;
      padding-bottom: 13.948vw;
    }

    .slide-1 h3 {
      font-size: 3.659vw;
      line-height: 4.192vw;
    }

    .phone-grid {
      right: -6.098vw;
      height: 65.396vw;
    }

    .phone-grid img {
      width: 17.226vw;
      filter: drop-shadow(-0.762vw 3.049vw 1.524vw rgba(0, 0, 0, 0.25));
    }

    .phone-grid img:nth-child(2n + 1) {
      top: calc(0.12 * 59.451vw);
    }

    .slide-1 ul {
      gap: 1.524vw;
    }

    .slide-1 ul li > span {
      font-size: 6.098vw;
      line-height: 7.012vw;
    }

    .slide-1 ul li > div {
      width: 21.646vw;
      box-shadow: 0px 0.915vw 0.915vw rgba(0, 0, 0, 0.15);
      border-radius: 1.22vw;
      padding: 3.049vw 1.829vw;
      margin-top: -1.524vw;
    }

    .slide-1 ul li > div h4 {
      font-size: 2.744vw;
      line-height: 3.125vw;
      margin-bottom: 1.524vw;
    }

    .slide-1 ul li > div p {
      font-size: 1.22vw;
    }

    .slide-controls {
      bottom: 4.345vw;
    }

    .slide-controls button {
      width: 4.573vw;
      height: 4.573vw;
    }

    .slide-controls button img {
      width: 3.049vw;
      height: 3.049vw;
    }

    .slide-controls button:nth-child(2) {
      margin-left: 1.524vw;
    }

    .progress-wrap {
      margin-left: 2.439vw;
    }

    .progress {
      width: 25.915vw;
      margin-right: 1.524vw;
    }

    .progress-wrap span {
      font-size: 1.22vw;
      line-height: 1.372vw;
    }

    .slide-2 {
      padding-top: 6.86vw;
    }

    .slide-2 ul li {
      opacity: 1;
      transform: initial;
    }

    .slide-2 h3 {
      font-size: 3.659vw;
      line-height: 4.192vw;
      margin-bottom: 1.22vw;
    }

    .slide-2 p {
      font-size: 1.524vw;
    }

    .slide-2 ul {
      gap: 1.829vw;
    }
  }

  @media screen and (max-width: 650px) {
    .section {
      padding-bottom: 10.667vw;
      height: 356.267vw;
    }

    .v-carousel {
      .max-w-1200-wrap {
        height: auto;
      }
    }

    .slide-1 {
      padding-top: 16.267vw;
      padding-bottom: 0;
    }

    .slide-1 h3 {
      font-size: 6.4vw;
      line-height: 7.467vw;
      margin-bottom: 10.667vw;
    }

    .phone-grid {
      position: initial;
      width: 79.733vw;
      margin-left: 7.2vw;
      margin-top: 15.467vw;
      margin-bottom: 34.933vw;
      gap: 3.467vw;
    }

    .phone-grid.hidden-sm {
      display: grid;
      height: auto;
    }

    .phone-grid.hidden-lg {
      display: none;
    }

    .phone-grid img {
      width: 100%;
      height: auto;
      margin: 1px 0px;
    }

    .phone-grid img:nth-child(2n + 1) {
      top: 16vw;
    }

    .slide-1 ul {
      flex-direction: column;
      gap: 6.4vw;
    }

    .slide-1 ul li {
      display: flex;
      flex-direction: row;
    }

    .slide-1 ul li h4 br {
      display: none;
    }

    .slide-1 ul li > span {
      font-size: 14.4vw;
      line-height: 17.067vw;
    }

    .slide-1 ul li > div {
      width: 74.133vw;
      box-shadow: 0px 2.133vw 2.133vw rgba(0, 0, 0, 0.15);
      border-radius: 3.2vw;
      padding: 6.133vw 5.333vw 4vw;
      margin-top: 0;
      margin-left: 6.133vw;
    }

    .slide-1 ul li > div h4 {
      font-size: 5.333vw;
      line-height: 6.133vw;
      margin-bottom: 2.133vw;
    }

    .slide-1 ul li > div p {
      font-size: 3.2vw;
    }

    .slide-controls {
      position: initial;
      margin-bottom: 0;
      margin-left: 4vw;
      margin-right: 4vw;
    }

    .slide-controls button {
      width: 9.6vw;
      height: 9.6vw;
    }

    .slide-controls button img {
      width: 6.4vw;
      height: 6.4vw;
    }

    .slide-controls button:nth-child(2) {
      margin-left: 2.667vw;
    }

    .progress-wrap {
      margin-left: 2.667vw;
      flex: 1;
    }

    .progress {
      flex: 1;
      width: auto;
      margin-right: 2.133vw;
    }

    .progress-wrap span {
      font-size: 3.2vw;
      line-height: 3.733vw;
    }

    .slide-2 {
      padding-top: 16vw;
      padding-bottom: 0;
    }

    .slide-2 h3 {
      font-size: 6.4vw;
      line-height: 7.467vw;
      margin-bottom: 3.2vw;
    }

    .slide-2 p {
      font-size: 3.2vw;
      margin-bottom: 16vw;
    }

    .slide-2 p br {
      display: none;
    }

    .slide-2 ul {
      flex-direction: column;
      gap: 12.8vw;
      margin-bottom: 19.467vw;
      margin-left: 4.8vw;
      margin-right: 4.8vw;
    }

    .section > .max-w-1200-wrap {
      position: absolute;
      bottom: 10.667vw;
    }
  }
}
</style>